#aam1 {
  border-top: none;
}
#aam2 {
  border-bottom: none;
  color: #1814f3;
  font-family: "IBM Plex Sans";
  font-size: 1.3rem;
  font-weight: 700;
}
#aam3 {
  float: right;
  padding: 0 1rem 0 1rem;
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}
#aam4 {
  margin-top: 2rem;
}
