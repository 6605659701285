.ecc1 {
  border-top: none;
  border-bottom: none;
}
.ecc2 {
  font-size: 1.2rem;
}
.ecc3 {
  margin-top: 2rem;
  width: 100%;
}
