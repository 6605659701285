#abm1 {
    border-bottom: none;
    color: #1814F3;
    font-family: "IBM Plex Sans";
    font-size: 1.3rem;
    font-weight: 700;
}
#abm2 {
    border-top: none;
}
#abm3 {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.5rem;
}

#selectedImgAddBanner {
    width: 200px;
    height: 150px;
    margin: 10px;
}