#assignFacultyCard {
    background-color: #bcd3e5;
    border-radius: 0.7rem;
    padding: 2rem;
}

#assignFacultyLabel {
    font-weight: 600;
    font-size: 1.1rem;
    font-family: "IBM Plex Sans";
}

#searchFacultyLabel {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    font-size: 1rem;
}

#searchFacultyForm {
    box-shadow: none;
    font-family: "IBM Plex Sans";
}

#facultyCard {
    border: 3px solid #bbc6db;
    border-radius: 0.4rem;
    height: 2rem;
    padding: 0.4rem;
    min-width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    margin-top: 0.6rem;
    color: #0d41a9;
    font-family: "IBM Plex Sans";
    font-size: 0.9rem;
}

#crossIcon {
    cursor: pointer;
    margin-left: 0.3rem;
}

#facultyCardRow {
    display: flex;
    overflow-x: scroll;
    width: 100%;
    min-height: 3.8rem;
    background-color: #fff;
    padding: 0.4rem;
    padding-left: 1rem;
    border-radius: 10px;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    font-family: "IBM Plex Sans";
    font-size: 0.9rem;
    font-weight: 500;
}

#facultyCardRow::-webkit-scrollbar {
    width: 4px;
    height: 10px;
}

#facultyCardRow::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 1rem;
}

#facultyCardRow::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

#zerofacultyText {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    margin-top: 0.9rem;
    font-size: 0.9rem;
}

#saveChangesButtondiv {
    display: flex;
    justify-content: center;
}