#cnf1 {
  margin-bottom: 1rem;
}
#cnf2 {
  background: linear-gradient(180deg, #396aff 0%, #381fd1 100%);
  width: 100%;
  border: none;
}
#cnf3 {
  background: red;
  width: 100%;
  border: none;
}
