#CardContainer {
  border: 1px solid #112244;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.2rem;
  font-family: "IBM Plex Sans";
  margin-left: 1rem;
}
#BatchStartEndDiv {
  background-color: #112244;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  border-radius: 0.4rem;
  font-weight: bold;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
#BatchSoltDiv {
  background-color: #0d41a9;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;
  padding-top: 0.12rem;
  padding-bottom: 0.12rem;
}
#BatchTimingLabel {
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 1.1rem;
  margin-bottom: 0.2rem;
}
#faq7 {
  display: flex;
  justify-content: center;
  align-items: center;
}
#faq9 {
  border: 1px solid #0141ab;
  /* margin-left: 0.5rem; */
}
#faq8 {
  font-size: 1.3rem;
}
#faq11 {
  border: 1px solid #e80e0e;
  color: #e80e0e;
  margin-left: -5.8rem;
}
#faq10 {
  font-size: 1.3rem;
}
