.rlb11 {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;
    width: 100%;
    cursor: pointer;
}

.rlb12 {
    color: #ffffff;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
}

.rlb13 {
    background: #0d41a9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
}

.rlb14 {
    height: 8rem;
    width: 8rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0d41a980;
}

.rlb15 {
    background: #3960b240;
    height: 11rem;
    width: 11rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}