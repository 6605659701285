body{
    background-color: #FAFAFB;
    font-family: IBM Plex Sans;
}

#teacher1{
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
    font-family: "IBM Plex Sans", sans-serif;
    margin-bottom: 1rem;
    color: #112244;
    background-color: #fff;
    width: 100%;
    padding: 12px 24px;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
    border-radius: 10px;
    font-family: IBM Plex Sans;
}
#teacher2{
    border: 2px solid black;
    width: 15rem;
    text-align: center;
   position: relative;
   top: -0.7rem;
    background-color: black;
    margin-bottom: 1rem;
    font-family: IBM Plex Sans;
}
#teacher3{
    display: flex;
    justify-content: center;
    font-family: IBM Plex Sans;
}
#teacher4{
    background: #fff;
    color: #124;
    font-family: IBM Plex Sans;
}
#teacher5{
    color: blue;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    font-family: IBM Plex Sans;
}
#teacher5:hover{
    font-weight: bolder;
    cursor: pointer;
    font-family: IBM Plex Sans;
}
#teacher6{
    margin-left: 0.3rem;
    font-size: 1rem;
    cursor: pointer;
    font-family: IBM Plex Sans;
}
/* #teacher7:hover{
    font-weight: 600;
    cursor: pointer;
    font-family: IBM Plex Sans;
} */

#teacher8{
    color: green;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    font-family: IBM Plex Sans;
}
#teacher8:hover{
    font-weight: bolder;
    cursor: pointer;
    font-family: IBM Plex Sans;
}
#teacher12 {
    color: orange;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    font-family: IBM Plex Sans;
}
#teacher13{
    color: rgb(255, 0, 0);
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    font-family: IBM Plex Sans;
}
#teacher10{
   box-shadow: none;
    border: none;
    font-family: IBM Plex Sans;
}
#teacher11{
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
    margin-top: 1rem;
    font-family: IBM Plex Sans;
}
#teachers12{
    width: 100%;
    background-image: linear-gradient(to top,#381FD1,#396AFF);
    height:2.2rem;
    color:white
}