#header2_1 {
    font-weight: 500;
    font-size: 0.9rem;
}
#linkh {
    text-decoration: none;
    color: rgb(255, 255, 255);
}
#header2_2 {
    border: none;
    font-weight: 500;
    font-size: 0.9rem;
    padding-top: 0;
}