#center1{
    padding: 1rem;
    margin-top: 0.4rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#center2{
    font-size: 1.3rem;
    font-weight: 500;
}
#center3{
    background-image: linear-gradient(to top,#381FD1,#396AFF);
    height:2.2rem;
    color:white
}