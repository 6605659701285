#addslot1 {
    background-color: #fff;
    padding: 1rem;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.3);
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-family: "IBM Plex sans";
  }
  #addslot2 {
    font-family: "IBM Plex sans";
    font-weight: 500;
    font-size: 1.2rem;
    color: #0d41a9;
  }
  #addslot3 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    font-family: "IBM Plex sans";
  }
  #addslot4 {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    font-family: "IBM Plex sans";
  }
  #addslot5 {
    background-color: #0d41a9;
    padding: 0.6rem;
    font-weight: 500;
    font-size: 1rem;
    border: none;
    box-shadow: none;
    width: 100%;
    margin-top: 1rem;
  }
  #addslot6 {
    color: #fff;
    padding: 0.5rem;
    font-family: "IBM Plex sans";
    margin-left: 0.5rem;
  }
  
  #addslot7 {
    width: 100%;
    background-color: slategray;
    color: #fff;
    border: none;
    box-shadow: none;
    padding: 0.5rem;
    font-family: "IBM Plex sans";
  }
  
  #addslot8 {
    background-color: #0d41a9;
    color: #fff;
    font-weight: 500;
    border: none;
    box-shadow: none;
    width: 100%;
    padding: 0.5rem;
    font-family: "IBM Plex sans";
  }
  
  #addslot9 {
    margin-bottom: 1rem;
  }
  #addslot10 {
    border: 1px solid #0d41a9;
    border-radius: 0.2rem;
    height: 2.7rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 0.4rem;
    width: 75%;
    background-color: #0d41a9;
    color: #fff;
    cursor: pointer;
    font-family: "IBM Plex sans";
    font-weight: 500;
  }
  #addslot11 {
    font-family: "IBM Plex sans";
    font-weight: 500;
    font-size: 1.2rem;
  }
  