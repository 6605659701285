#dashcard1 {
  padding: 1.3rem;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  box-shadow: 9px 12px 37px 0px rgba(0, 0, 0, 0.12);
}
#dashcard2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.7rem;
}
#dashcard4{
    color: #718EBF;
    font-size: 16px;
    font-weight: 400;
}
#dashcard5{
    color:#232323;
    font-size: 24px;
    font-weight: 600;
}
