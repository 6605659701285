#cardMainView{
    background-color: #D1E0EB;
    border-radius: 0.5rem;
    padding: 1.5rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
#bannerImg{
    object-fit: contain;
    width: 100%;
    height: 8rem;
}
#bannerHeaderText{
    font-family: "IBM Plex Sans";
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
#bannerDescpText{
    font-family: "IBM Plex Sans";
    font-size: 0.95rem;
    font-weight: 400;
}
#bannerTopText{
    color: #1814F3;
    font-family: "IBM Plex Sans";
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 1.1rem;
}
#dotted-border {
    border: 1px dashed red; 
    padding: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 3rem;
    border-radius: 0.7rem;
    color: red;
    cursor: pointer;
    margin-left: 1rem;
}
#dotted-border:hover{
    border: 1px dashed wheat; 
    padding: 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    height: 3rem;
    border-radius: 0.7rem;
    color: white;
    cursor: pointer;
    background-color: red;
    transition: 1s all linear;
    margin-left: 1rem;
}
#buttonMainDiv{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1.5rem;
}

#loadingBanner {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}