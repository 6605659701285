#help1 {
  background-color: white;
  padding: 1.5rem;
  margin-top: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 5px;
}
#help2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #112244;
}
#help3{
    background-color: white;
    padding: 1.5rem;
    margin-top: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
    border: 1px solid blue;
}
#help4{
    display: flex;
    justify-content: center;
    align-items: center;
}
#help5{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.3rem;
}
#help6{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #747576;
    font-weight: 500;
    font-size: 0.9rem;
}
#help7{
    font-size: 1rem;
    font-weight: 500;
}
#help8{
    color: #747576;
    font-weight: 500;
    font-size: 0.9rem;
}
#help9{
    font-size: 1rem;
    font-weight: 500;
    margin-top: 0.9rem;
}
