#nc1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 22em;
}
.nc2 {
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
  margin-top: 0rem;
}
.nc3 {
  color: #596a8c;
  font-size: 1rem;
  font-weight: 600;
}
.nc4 {
  color: #0d41a9;
  font-size: 2rem;
  font-weight: 600;
}
.nc5 {
  color: #112244;
  height: 2rem;
  width: 2rem;
}
.nc6 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.nc7 {
  margin-top: 1rem;
  background: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem 1rem;
}
.nc8 {
  padding: 1rem 2rem 1rem 2rem;
}
.nc9 {
  /* padding: 1rem; */
  background: #ffffff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.nc10 {
  background: #ffffff;
  margin: 0.5rem 0 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.3rem;
  color: #1814f3;
  font-weight: 600;
}
.nc11 {
  border-bottom: 3.5px solid #1814f3;
}
