#headerText {
  color: #1814f3;
  font-family: "IBM Plex Sans";
  font-size: 1.3rem;
  font-weight: 700;
  margin-bottom: 1.1rem;
}
#headerButtonView {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0.5rem;
}
#headerButtonIcon {
  cursor: pointer;
}
#headerButtonIcon:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: 1s all ease-in-out;
}
