#teacherviewdetails1{
    width: 6rem;
    height: 6rem;
    margin-top: 0.9rem;
    border-radius: 60rem;
}
#teacherviewdetails2{
    margin-top: 4rem;
}
#teacherviewdetails3{
    font-size: 1.1rem;
    font-weight: bolder;
    margin-bottom: 0.3rem;
}
#teacherviewdetails4{
    margin-top: 2.3rem;
}
#teacherviewdetails5{
    margin-top: 3rem;
    background: black;
    color: white;
    text-align: center;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    border-radius: 0.3rem;
    font-size: 2rem;
    font-weight: 600;
}
/* #teacherviewdetails6{
    margin-top: 3rem;
    margin-bottom: 3rem;
} */
#teacherveiwdetails7{
    width: 6rem;
    height: 6rem;
    margin-top: 0.9rem;
    border-radius: 60rem;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    font-size: 3rem;
    font-weight: 600;
}
#teacherveiwdetails8{
    margin-left: 1rem;
}
#teacherveiwdetails9{
    font-size: 1.1rem;
    font-weight: 600;
    color:black;
}
#teacherveiwdetails10{
    font-size: 0.9rem;
    font-weight: 500;
    color: rgb(155, 154, 154);
    margin-top: 0.2rem;
}
#teacherveiwdetails11{
    margin-top: 0.3rem;
}
#teacherveiwdetails12{
    padding: 1rem;
    /* margin-top: 0.4rem; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#teacherveiwdetails13{
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#teacherveiwdetails14{
    margin-top: 0.8rem;
}
#teacherveiwdetails15{
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 1.6rem;
}