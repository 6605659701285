body {
  background-color: #fafafb;
}

#rp1 {
  background-color: #fff;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.3);
  border-radius: 10px;
  font-family: "IBM Plex Sans", sans-serif;
  padding: 1rem;
  color: #124;
}
#rp2 {
  gap: 10px;
}
#rp3 {
  font-family: "IBM Plex Sans", sans-serif;
  padding: 8px 16px;
  background-color: #fff;
  color: #f23935;
  border-radius: 0.4rem;
  border: 1px solid #f23935;
  box-shadow: none;
  margin-right: 10px;
}

#rp4 {
  background-color: #124;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 0.4rem;
  padding: 8px 16px;
  margin-right: 10px;
}

#rp5 {
  background-color: #d1e0eb;
  /* box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30); */
  border-radius: 10px;
  padding: 1rem;
  margin-top: 1rem;
  text-align: left;
}

#rp9 {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
