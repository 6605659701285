#acm1 {
    border-bottom: none;
    color: #1814F3;
    font-family: "IBM Plex Sans";
    font-size: 1.3rem;
    font-weight: 700;
}
#acm2 {
    border-top: none;
}

#selectedCourseDiv {
    border: 1px solid #000;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 0.9rem;
    margin-bottom: 5px;
}
