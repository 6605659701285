body {
  background-color: #fafafb;
}

#services4 {
  background-color: #d1e0eb;
  /* box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30); */
  border-radius: 10px;
  font-family: "IBM Plex Sans", sans-serif;
  padding: 1rem;
  color: #124;
  margin-bottom: 1rem;
}

#services9 {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}