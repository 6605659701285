#cdt1{
    background-color: orangered;
    color: white ;
    font-size: 1.4rem;
    text-transform: uppercase;
 border-radius: 5px;
 padding: 10px 20px;
 margin-top: 1.4rem;

}
#cdt2{
    margin-top: 1.4rem;
    padding: 0px;
}
#cdt3{
    padding-left: 0px;
}
#cdt4{
    padding-right: 0px;
}
#cdt5{
    background-color: rgb(32, 32, 32);
    color: white;
    padding: 10px 20px;
    margin-top: 2rem;
 align-items: center;
    display: flex;
    border-radius: 0px 100px 0px 0px;
}
#cdt6{
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0px;
    margin-top: 1.4rem;

}
#cdt7{
    margin-top: 1rem;
}
#cdt8{
    color: orangered;
    cursor: pointer;
}