/* #msa1{
    background-color: #111111;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 2rem;
}
#msa2{
    background-color: rgb(213, 230, 237);
    margin-top: 1.5rem;
    padding: 10px 20px;
    color: #111111;
    
}
#msa3{
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: sans-serif;
    color: rgb(47, 47, 47);
    gap: 10px;
    align-items: center;
    display: flex;
}
#msa4{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
#msa5{
    background-color: #124;
    color: #f8fafe;
    font-weight: 500;

    border: none;
    box-shadow: none;
    padding: 10px 20px;
    font-family: "IBM Plex sans", sans-serif;
    font-weight: 700;
    gap: 5px;
    font-size: .8rem;
  
}
#msa6{
    background-color: transparent;
    box-shadow: none;
    border: none;
    color: orangered
}
#msa7{
    padding: 1rem;
    background-color: #fff;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
    border-radius: 10px;
} */
.roundLargeButtonContainer {
  background-color: #fff;
  padding: 14px;
  border-radius: 10px;
  height: 280px;
  /* overflow-y: scroll; */
}

.detailsContainer {
  background: #f3f5fc;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
}
.detailContainerWrapper {
  height: 250px;
  overflow-y: scroll;
}
.detailsHeader {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  color: #112244;
}
.thirdCol {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  color: #1080f0;
  text-decoration: underline;
  cursor: pointer;
}
#managesubadmin1 {
  box-shadow: none;
  border: none;
  background-image: linear-gradient(to top, #381fd1, #396aff);
}
