.fromLabel {
  font-size: 16px;
  color: #124;
}
.addBtn {
  font-size: 1.8rem;
}
.categoryContainer {
  border-radius: 10px;
  padding: 20px;
  height: 300px;
  background: #f3f5fc;
  overflow-y: auto;
}
.list-group-item {
  background: #f3f5fc;
  border: none !important;
}
.categoryHeaderRow {
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
}
.categoryHeader {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 500;
}
.categoryRow {
  display: flex;
  justify-content: space-between;
}
.categoryName {
  color: #fff;
  background: #1814f3;
  font-family: "Poppins";
  width: 12rem;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px;
}

.buttons {
  display: flex;
  font-family: "Poppins";
}
.removeBtn {
  margin-left: 10px;
  font-size: 14px;
  color: #ae2a00;
  margin-top: 5px;
  border: 1px solid #fff;
  background-color: #fff;
}
.editBtn {
  font-size: 14px;
  color: #ed122c;
  margin-top: 5px;
  border: 1px solid #fff;
  background-color: #fff;
}
.addcours100 {
  display: flex;
}
.addcours101 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1rem;
}
.addcours102 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
