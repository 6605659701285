#logm1 {
  margin-bottom: 1rem;
}
#logm2 {
  width: 100%;
  border-radius: 0%;
  background-color: #e8dd0c;
  border: none;
  color: black;
  font-weight: 500;
}
#logm3 {
  width: 100%;
  border-radius: 0%;
  background-color: #a8acb0;
  border: none;
  color: black;
  font-weight: 500;
}
#logm4 {
  border-radius: 0%;
}
#suspend4 {
  width: 100%;
  background-color: #000b20;
  color: white;
  border: none;
  box-shadow: none;
}
#suspend5 {
  width: 100%;
  background-color: #ed122c;
  color: white;
  border: none;
  box-shadow: none;
}
