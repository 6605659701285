.login1 {
  background-size: cover;
  background-image: url("../../assets/login/backgroundImage.png");
  min-height: 100vh;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login2 {
  background-color: rgba(255, 255, 255, 0.2);
  width: 28%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px rgba(0, 0, 0.5, 0.3);
  border-radius: 20px;
  border: 2px solid rgba(158, 189, 236, 0.49);
  backdrop-filter: blur(12.5px);
}
@media screen and (max-width: 900px) {
  .login2 {
    background-color: rgba(255, 255, 255, 0.2);
    width: 90%;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 4px rgba(0, 0, 0.5, 0.3);
    border-radius: 20px;
    border: 2px solid rgba(158, 189, 236, 0.49);
    backdrop-filter: blur(12.5px);
  }
}
.login3 {
  height: 4.2rem;
}
.login4 {
  display: flex;
  justify-content: center;
}
.login5 {
  text-align: center;
  font-family: "IBM Plex Sans";
  font-weight: 900;
  color: white;
  font-size: 1.2rem;
  letter-spacing: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.login6 {
  font-family: "IBM Plex Sans", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}
.login7 {
  border: none;
  background: #f3f4f6;
  height: 2.7rem;
  box-shadow: none;
}
.login7::placeholder {
  font-size: 15px;
}
#login8 {
  width: 100%;
  background-color: #023e8a;
  border: none;
  height: 45px;
  margin-top: 1.1rem;
  margin-bottom: 0.5rem;
}
.login9 {
  font-size: 0.8rem;
  text-align: center;
}
.login10 {
  color: #332a69;
  font-weight: 600;
  cursor: pointer;
}
.forgotPassword {
  cursor: pointer;
}