.studentDetailsCard{
    margin-top: 2rem !important;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    height: 800px;
}
.studentText{

}
#profilePic {
    /* display: flex;
    justify-content: flex-end; */
    align-items: center;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    overflow: hidden;
}
#profilePic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.personalDetailsText{
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-size: 18px;
}
.personalDetailsTextColor{
    color: #8F8F8F;
}

#assignmentCardContainer {
    border: 1.9px solid #87B6DD;
    border-radius: 0.6rem;
    margin-top: 1.9rem;
    margin-bottom: 1.9rem;
    height: 280px;
}

#assignementCardlabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "IBM Plex Sans";
    font-weight: 700;
}

#assignementCardStatusdiv {
    border-radius: 3rem;
    padding: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 80%;
}

#assingmentCardStatusCol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#assignmentDescription {
    text-align: justify;
    font-family: "IBM Plex Sans";
    font-size: 0.9rem;
    margin-top: 0.9rem;
    margin-bottom: 0.55rem;
    color: #707070;
}

#detailButton {
    color: #396AFF;
    border: 1px solid #396AFF;
    border-radius: 0.3rem;
    width: 100%;
    background-color: transparent;
    margin-top: 1.5rem;
    margin-bottom: 0.7rem;
    padding-top: 0.53rem;
    padding-bottom: 0.53rem;
    font-family: "IBM Plex Sans";
}

#submitButton {
    background-color: #396AFF;
    border-radius: 0.3rem;
    color: white;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 0.7rem;
    padding-top: 0.53rem;
    padding-bottom: 0.53rem;
    font-family: "IBM Plex Sans";
}

#downloadText {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    color: #396AFF;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 0.2rem;
}

#downloadText:hover {
    font-weight: bold;
    transition: 0.5s all ease;
}

#labelRow {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

#submittedButton {
    background-color: #22C55E;
    color: white;
    width: 100%;
    border-radius: 0.3rem;
    margin-top: 1.5rem;
    margin-bottom: 0.7rem;
    padding-top: 0.53rem;
    padding-bottom: 0.53rem;
    border: none;
    font-family: "IBM Plex Sans";
}
#infoButton{
    margin-top: 1.5rem;
        margin-bottom: 0.7rem;
}
.kGckkd{
    font-family: "IBM Plex Sans" !important;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
    padding: 5px;
}
#assignmentFileText {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 0.95rem;
}
.tabContainer{
    overflow: scroll;
    height: 500px;
}