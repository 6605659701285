.course1 {
    background-color: #f3f4f6;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    padding: 1rem;
    min-height: 22em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.createcourse {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 22em;
}

.courses2 {
    background: #ffffff;
    /* box-shadow: 1px 3px 9.300000190734863px 0px #11224414; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 22em;
    /* padding: 1rem; */
    width: 100%;
}

.courses3 {
    background: #f3f5fc;
}

.course4 {
    color: #000000;
    margin: 1rem 0 1rem 0;
    font-weight: 600;
    font-family: "IBM Plex Sans";
    font-size: 1.4rem;
}

.course5 {
    margin-bottom: 1.6rem;
}

.course6 {
    border: 1.45px solid black;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
}

.course7 {
    border: none;
    box-shadow: none;
}

.course7:focus,
.course7:active {
    box-shadow: none;
}

.course8 {
    font-family: "IBM Plex Sans";
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}