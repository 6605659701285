/* .MuiPaper-root {
  border: none;
} */
.acm1 {
    display: flex;
    justify-content: flex-end;
  }
  .acm2 {
    height: 1.5rem;
    width: 1.5rem;
    color: lightgrey;
    cursor: pointer;
  }
  .acm3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: black;
    font-family: "IBM Plex Sans";
  }
  .acm4 {
    background: #112244;
    border-color: #112244;
    width: 100%;
  }
  .acm5 {
    padding-left: 1rem;
    padding-right: 0.8rem;
  }
  #acm6 {
    font-family: "IBM Plex Sans";
    font-weight: 500;
  }
  