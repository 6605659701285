.ratingContainer {
  background: #fff;
  border-radius: 10px;
  padding: 10px 20px;
}
.ratingWrapper {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1.6px solid #dedede;
}
.verticalLine {
  width: 6px;
  height: 17px;
  border-radius: 10px;
  background: #0ec281;
  display: inline-block;
  margin-bottom: 0 !important;
  margin-right: 15px;
}

.ratingTxt {
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  font-family: "DM Sans", sans-serif;
}

#commentsTxt {
  text-align: flex-start;
  font-size: 0.9rem;
  font-weight: 500;
  color: #222222;
  font-family: "DM Sans";
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

#commentGivenBy {
  text-align: flex-start;
  font-size: 0.7rem;
  font-weight: 400;
  color: #838383;
  font-family: "DM Sans";
}

#givenCommentTxt {
  text-align: flex-start;
  font-size: 0.7rem;
  font-weight: 500;
  color: #222222;
  font-family: "DM Sans";
}
.ratingRowContainer {
  display: flex;
  align-items: center;
}

.reviewAvg {
  margin-right: 10px;
  font-weight: bold;
  font-size: 36px;
}
.reviewCount {
  font-size: 12px;
  color: #838383;
  font-family: "IBM Plex Sans", sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profileImage {
  width: 50px;
  height: 50px;
  background: darkblue;
  color: #fff;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "IBM Plex Sans";
}
.profileName {
  font-family: "IBM Plex Sans";
  font-weight: 500;
}
.commentText {
  font-size: 14px;
  color: #838383;
  font-family: "IBM Plex Sans", sans-serif;
}
