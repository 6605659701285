body{
    background-color: #FAFAFB;
  }

  #tc1{
    background-color: #fff;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
    border-radius: 10px;
    font-family: "IBM Plex Sans", sans-serif;
    padding: 1rem;
    color: #124;
    font-size: 1.2rem;
    font-weight: 600;
}

#tc2{
    font-family: "IBM Plex Sans", sans-serif;
    padding: 8px 16px;
    background-color: #fff;
    color: #F23935;
    border-radius: .4rem;
    border: 1px solid #F23935;
    box-shadow: none;
    margin-right: 10px;
}


#tc3{
    background-color: #124;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: .4rem;
    padding: 8px 16px;
    margin-right: 10px;
}
#tc5{
    background-color: #D1E0EB;
    /* box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30); */
    border-radius: 10px;
    padding: 1rem;
    margin-top: 1rem;
    text-align: left;
}