#AlabelRow {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

#AModalCardlabel {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "IBM Plex Sans";
    font-weight: 700;
}

#AModalCardStatusCol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#AModalCardStatusdiv {
    border-radius: 3rem;
    padding: 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 80%;
}

#AModalFileText {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 0.95rem;
}

#AModaldocIcon {
    margin-right: 0.5rem;
}

#AModalCardStatusCol {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

#AModaldownloadText {
    font-family: "IBM Plex Sans";
    font-weight: 400;
    color: #396AFF;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 0.2rem;
}

#AModaldownloadText:hover {
    font-weight: bold;
    transition: 0.5s all ease;
}

#AModalDescrip {
    text-align: justify;
    font-family: "IBM Plex Sans";
    font-size: 0.9rem;
    margin-top: 0.9rem;
    margin-bottom: 0.55rem;
    color: #707070;
}

#AModallastSubText {
    font-family: "IBM Plex Sans";
    font-weight: 500;
    font-size: 0.95rem;
    margin-top: 0.4rem;
}

#AModalsubmitButton {
    background-color: #396AFF;
    border-radius: 0.3rem;
    color: white;
    width: 20%;
    padding-top: 0.53rem;
    padding-bottom: 0.53rem;
    font-family: "IBM Plex Sans";
}
.modal-content{
    border-radius: 1rem;
}