#cardContainter {
  font-family: "IBM Plex Sans";
}

#cc1 {
  background: #f8f9fe;
  border: 1px solid #dedede;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 1rem;
  padding: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 1.5rem;
  margin-left: 0.5rem;
  position: relative;
  cursor: pointer;
}

#cc2 {
  color: #ff0000;
  font-weight: 600;
  font-family: "IBM Plex Sans";
  text-align: justify;
  margin: 0.5rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  /* position: relative; */
  /* padding-left: 1rem;
      padding-right: 1rem; */
}

#cc3 {
  display: flex;
  justify-content: center;
  position: relative;
}

#cc4 {
  font-size: 0.6rem;
  font-weight: 500;
  margin: 0 0 0.5rem 0;
}

#cc5 {
  margin: 0.5rem 1rem 2rem 0;
  font-family: "IBM Plex Sans";
  font-size: 1rem;
  font-weight: 400;
}

#tcc6 {
  text-decoration: none;
  color: initial;
}

#tcc7 {
  width: 2rem;
  height: 2rem;
  background-color: red;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1.8rem;
  top: -1.7rem;
}

#tcc8 {
  color: white;
}

#tcc9 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  position: absolute;
  left: 0;
  top: 0.1rem;
}

#tcc10 {
  height: 2rem;
  width: 2rem;
  margin-top: -0.28rem;
}

.tcc11 {
  font-size: 0.7rem;
  font-weight: 700;
  color: #1c56c9;
  text-transform: uppercase;
  background: #ebf2fa;
  padding: 0.2rem;
  border-radius: 0.1rem;
}

.tcc12 {
  display: flex;
}

.tcc13 {
  font-weight: 600;
  color: #112244;
  font-size: 1.2rem;
}

.tcc14 {
  display: flex;
  justify-content: flex-end;
}

.tcc15 {
  /* width: 100vw; */
  width: 100%;
  height: 25vh;
  border-radius: 0.5rem;
  cursor: pointer;
}

.tcc16 {
  color: #112244;
  padding: 0.5rem 0 0.5rem 1rem;
}

.tcc17 {
  width: 100%;
  background: #112244;
  border-color: #112244;
  font-size: 0.8rem;
  --bs-btn-hover-color: #112244;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #112244;
  --bs-btn-active-color: #112244;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #112244;
}