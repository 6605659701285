
body{
  background-color: #FAFAFB;
}

/* #au1{
    background-color: #FFF;
    margin-top: 1.4rem;
    margin-bottom: 1rem;
    padding: 10px 20px;
    color: #112244;
  font-family: "IBM Plex sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
display:'flex';
justify-content: center;
border-radius: 10px;
gap: 10px;
} */
#au2{
  padding: 1rem;
  background-color: #fff;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
  border-radius: 10px;
}
Table{
    font-family: "IBM Plex Sans", sans-serif;
}

.paginationBttns {
  width: 95%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  color: orangered;
  margin-top: 2rem;
}

.paginationBttns a {
  padding-top: .2rem;
  padding-bottom: .2rem;
  padding-left: .4rem;
  padding-right: .4rem;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid orangered;
  color: orangered;
  cursor: pointer;
  text-decoration: none;
}
.paginationBttns a:hover {
  color: white !important;
  background-color: orangered;
}

.paginationActive a {
  color: white !important;
  background-color: orangered;
}
#au1 {
  background-color: #fff;
  margin-top: 1.4rem;
  margin-bottom: 1rem;
  padding: 10px 20px;
  color: #112244;
  font-family: "IBM Plex sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
  display: flex;
  /* Use flexbox */
  justify-content: space-between;
  /* Align items to the start and end */
  align-items: center;
  /* Center items vertically */
  border-radius: 10px;
}

.searchInputContainer {
  display: flex;
  justify-content: end;
  /* Use flexbox */
  align-items: center;
  /* Center items vertically */
}

.searchInput {
  border-radius: 8px !important;
  border: 2px solid #eff4fa;
  width: 262px;
  height: 40px;
  padding: 10px;
  font-size: 16px;
}

.searchInput:hover {
  border: 2px solid #eff4fa !important;
}

.detailsBtn{
  color: #1080F0;
  cursor: pointer !important;;
  font-family: "IBM Plex sans", sans-serif;
  font-size: 14px;
  text-decoration: underline;
}
#user1 {
  color: #596A8C;
  font-size: 24px;
  font-weight: 600;
}
#user2 {
  color: #596A8C;
  font-size: 20px;
  font-weight: 600;
}