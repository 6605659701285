#downloadText {
  font-family: "IBM Plex Sans";
  font-weight: 400;
  color: #396aff;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 0.2rem;
}
#downloadText:hover {
  font-weight: bold;
  transition: 0.5s all ease;
}
#classNotesContainer {
  height: 130px;
  background-color: #bcd3e5;
  border-radius: 0.7rem;
  margin-top: 1.5rem;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  padding-bottom: 1.5rem;
}
#labelText {
  font-weight: bold;
  font-family: "IBM Plex Sans";
  font-size: 1.2rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
#underLine {
  background-color: grey;
  height: 2px;
  margin-bottom: 1.5rem;
}
#downloadCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
#noMaterialAvailable {
  font-family: "IBM Plex Sans";
}
