.sidebar {
  padding: 0;
  background-color: lightblue;
}

#sidebar1 {
  min-height: 100vh;
  background-image: radial-gradient(circle at 17% 77%, rgba(17, 17, 17,0.04) 0%, rgba(17, 17, 17,0.04) 50%,rgba(197, 197, 197,0.04) 50%, rgba(197, 197, 197,0.04) 100%),radial-gradient(circle at 26% 17%, rgba(64, 64, 64,0.04) 0%, rgba(64, 64, 64,0.04) 50%,rgba(244, 244, 244,0.04) 50%, rgba(244, 244, 244,0.04) 100%),radial-gradient(circle at 44% 60%, rgba(177, 177, 177,0.04) 0%, rgba(177, 177, 177,0.04) 50%,rgba(135,135,135, 0.04) 50%, rgba(135,135,135, 0.04) 100%),linear-gradient(197deg, rgb(0,10,14),rgb(0,9,28));
  /* display: flex; */
  justify-content: left;
  align-items: flex-start;
  width: 100%;

}
#sidebar_2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 800;
  color: rgb(255, 255, 255);
  padding-top: 0.5rem auto;
  gap: 20px;
  margin-bottom: 1rem;
}
#sidebar_3 {
  background: transparent !important;
  width: 100%;
}
#sidebar_4 {
  background: none !important;
  border: none;
}
#sidebar_5 .accordion-button.collapsed {
  background-color: transparent !important;
  font-family: "IBM Plex Sans", sans-serif;
  box-shadow: none;
  content: transperent;
  font-weight: 700;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  padding: 8px;
}
#sidebar_5 .accordion-button:not(.collapsed) {
  background-color: transparent !important;
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  box-shadow: none;
  content: hidden;
}
#sidebar_6 {
  padding: 0px;
}

.sidebar-container {
  height: 100%;
  /* position: fixed; */
  /* top: 0; */
  /* left: 140; */
  /* overflow-y: scroll; */
}

.nav-link {
  transition: width 2s;
  color: rgb(242, 242, 242)smoke;
}

.nav-link.active {
  color: #fff !important;
  border-left: 10px solid orangered;
  padding: .5rem 3rem;

  width: 100%;
  text-align: center;
}

.accordion {
  background: transparent;
}

.sidebar .navbar {
  padding: 0;
}

.sidebar .nav-link {
  padding: 10px 20px;
  color: #333;
}

#logo-container{
  background-color: #fff;
  min-height: 100px;
  width: 100%;
}

.sidebar .nav-link:hover,
.sidebar .nav-link:focus {
}
.accordion-button.collapsed {
  background: white;
}

.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.sidebar .accordion-button {
  background-color: transparent;
  color: #ffffff;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
}

.sidebar .accordion-button:hover,
.sidebar .accordion-button:focus {
  background-color: transparent;
}

.main-content {
  padding: 20px;
}
#sidebar7{
  width: 90%;
  bottom: 20px;
  margin-left: 1rem;
}
#sidebar8{
  margin-left: 0.7rem;
}
