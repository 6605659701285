
body{
    background-color: #FAFAFB;
  }
#abt1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #124;
    padding: 12px 24px;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
    background: #fff;
    border-radius: 10px;
}

#abt2 {
    display: flex;
    justify-content: center;
    align-items: center;
}
#abt3 {
    font-size: 1.3rem;
    font-weight: 500;
}
#abt4 {
    text-align: justify;
    font-weight: 500;
}
#abt5 {
font-family: "IBM Plex Sans", sans-serif;
padding: 8px 16px;
background-color: #fff;
color: #F23935;
border-radius: .4rem;
border: 1px solid #F23935;
box-shadow: none;
}
#abt6 {
    display: flex;
    justify-content: flex-end;
}
#abt7 {
    display: flex;
    margin: 0 1rem 0 1rem;
}
#abt8 {
    margin: 2rem 0 2rem 0;
}
#abt9 {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#abt10 {
    height: 5rem;
    width: 10rem;
}
#abt11{
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color:#D1E0EB;
    /* box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30); */
    padding: 1rem;
    border-radius: 10px;

}
#abt12{
    background-color: #1814F3;
    padding: 1rem;
    border-radius: .2rem;
    color: #fff;
    font-weight: 700;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
#abt13{
    background-color: #124;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: .4rem;
    padding: 8px 16px;
    margin-right: 10px;
}