#editnewcourse1 {
    background-color: #F2F7FF;
    border: 1px solid #CED4DA;
}

#editnewcourse2 {
    width: 100%;

}

#editnewcourse3 {
    background-color: #124;
    border: none;
    box-shadow: none;

    width: 100%;
}

#editnewcourse4 {
    cursor: pointer;
    font-size: 1.5rem;
}

#editnewcourse5 {
    width: 15rem;
    height: 25vh;
    border-radius: 1rem;
}

#editnewcourse6 {
    display: flex;
    justify-content: center;
}

#editnewcourse7 {
    color: #332A69;
    font-weight: 900;
    font-family: "IBM Plex Sans", sans-serif;
}

#editnewcourse8 {
    font-size: 1rem;
    margin-bottom: .5rem;
    color: rgb(93, 106, 132);
    font-weight: 500;
}

#editnewcourse9 {
    background-color: #F23935;
    box-shadow: none;
    border: none;
    width: 100%;
    font-weight: 600;
    padding: .6rem;
}
#dropdown-basic-button{
    text-align: start;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    background-color: #F2F7FF !important;
    border: 1px solid #CED4DA !important;
    color: #212529 !important;
}
#dropdown-basic-item{
    width: 100% !important;
}