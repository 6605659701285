.Financetext-1 {
  color: #0d41a9;
  font-family: IBM Plex Sans;
  font-size: 30px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
}

.Financetext-2 {
  color: #8b8b8b;
  font-family: IBM Plex Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.Financetext-3 {
  color: #231a1a;
  font-family: IBM Plex Sans;
  font-size: 28px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.Financetext-4 {
  color: #231a1a;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

#FinanceBarcharHeader {
  color: #596a8c;
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

#Financs-Table {
  text-align: center;
}

#Financs-Table > :not(tbody) > * > * {
  background-color: #0037a4;
  color: aliceblue;
}

#Financs-Table > :not(tbody) > * > * {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

#Financs-Table > :not(thead) > * > * {
  font-family: IBM Plex Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.FinanceTableStatus {
  background: #eeeeee;
  border-radius: 10px;
  padding: 0.3rem;
}

.FinanceComponent {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
}

#FinanceBarchart {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
}

.FinanceComponent2-1,
.FinanceComponent2-2,
#Finance1 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 1rem;
  padding: 1rem;
}

.FinanceComponent2-1 {
  margin: 22px 0;
}

.FinanceComponent2-1 .btn.active {
  border-bottom: 5px solid #1814f3;
  color: #1814f3;
}

.FinanceComponent2-1 .btn {
  margin: 0 10px;
  background-color: transparent;
  border: 0;
  color: #839cc7;
  padding: 10px;
}

#Finance1 {
  display: flex;
}

.Finance1-1 {
  justify-self: start;
}

.Finance1-2 {
  margin-left: auto;
  width: 20%;
  margin: auto;
}

.Finance1-2 img {
  margin: 2px;
  border-radius: 50%;
}

#Financepagination .page-item.active > .page-link {
  background-color: #1814f3 !important;
  cursor: pointer;
}

#Financepagination .page-item {
  margin: 04px;
}
