
body{
  background-color: #FAFAFB;
}
#loaderContainerContactUs{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}
#contact1{
width: 20%;
}
#contact2{
margin-top: 2rem;
font-family: 'IBM Plex Sans', sans-serif;
font-size: small;
font-weight: 500;
padding-left: 2rem;
padding-right: 2rem;
}
#contact3{
font-size: medium;
}
#contact4 {
    overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
  padding: 1rem;
}
#contact5{
  font-size: 1.2rem;
  color: #124;

}
#contact6{
  background-color: #fff;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
  padding: 12px 24px;
  border-radius: 10px;
  margin-bottom: 1rem;
}