#sc1{
    background-color: rgb(25, 25, 25);
    color: #fff;
    font-family: jost;
    font-size: large;
    padding: 10px 20px;
    border-radius: 10px;
    margin-top: 1.5rem;
}
#sc2{
    margin-top: 2rem;
    margin-bottom: 1.4rem;
}
#sc3{
    background-color: rgb(103, 27, 0);
    margin-top: 2rem;
    padding: 10px 20px;
    color: #fff;

}
#sc4{
    background-color: rgb(234, 244, 255);
    padding: 20px 30px;
}
#sc5{
    margin-bottom: 1.5rem;
}