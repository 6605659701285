.AcademicaddacourseadminBoxfont {
  font-family: "IBM Plex Sans";
}

.AcademicaddacourseadminBox {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
}

.Academicaddacourseadminheading {
  margin-right: auto;
  font-size: 1.2rem;
  font-weight: 500;
}

.Academicaddacourseadmintext1 {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 500;
  color: #1080f0;
  text-decoration: underline;
  cursor: pointer;
}

.Academicaddacourseadmintext2 {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 400;
  color: #8f9bb1;
}

.Academicaddacourseadmintext3 {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 500;
  color: #112244;
}

.Academicaddacourseadmintext4 {
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-weight: 500;
  color: #1080f0;
  cursor: pointer;
}

.Academicaddacourseadmintext5 {
  font-family: "IBM Plex Sans";
  font-size: 0.9rem;
  font-weight: 500;
  color: #616161;
  margin-top: 0.7rem;
}

.AcademicaddacourseadminCustomCheckbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 1rem;
}

.AcademicaddacourseadminCustomCheckbox input[type="checkbox"]:checked {
  color: #fff;
  background-color: black;
}

.AcademicaddacourseadminCustomCheckbox input[type="checkbox"] {
  background-color: #a8a8a8;
  border: 1px solid #a8a8a8;
  padding: 10px;
}

.AcademicaddacourseadminModalsubmitbtn {
  background: linear-gradient(180deg, #3968fe 0%, #3824d5 100%) !important;
  width: 100%;
  margin: auto;
  height: (78px);
  padding: 30px, 39px, 30px, 39px;
  border-radius: 17px;
}

.AcademicaddacourseadminScrollcomp {
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.AcademicaddacourseadminScrollcomp::-webkit-scrollbar {
  width: 10px;
}

.AcademicaddacourseadminScrollcomp::-webkit-scrollbar-thumb {
  background-color: #0a447e;
  border-radius: 10px;
}

.AcademicaddacourseadminScrollcomp::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* .AcademicaddacourseadminCustomCheckboxLabel {
    display: inline !important;
    cursor: pointer;
  } */
.Passwordhideunhidecomponent {
  position: absolute;
  top: 65%;
  right: 1%;
  bottom: 20px;
  padding: 0;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
}

#Academicaddacourseadminnumber {
  -moz-appearance: textfield !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

#Academicaddacourseadminnumber::-webkit-inner-spin-button,
#Academicaddacourseadminnumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#Academicaddacourseadminnumber {
  -moz-appearance: textfield;
}


#addCourseAdminModalAlertLine {
    color: rgb(255, 81, 0);
    font-size: smaller;
    margin-bottom: 1.5rem;
    font-family: "IBM Plex Sans";
}