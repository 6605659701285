#CardMainView{
    border: 1px solid gray;
    border-radius: 1.3rem;
    padding: 1.3rem;
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
}
#profileNameView{
    display: flex;
    flex-direction: row;
    align-items: center;
}
#profileImg{
    object-fit: contain;
    width: 3rem;
    height: 3rem;
    border-radius: 8rem;
}
#nameText{
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "IBM Plex Sans";
}
#courseManageByView{
    background-color: #EBF0F5;
    border-radius: 0.5rem;
    padding: 0.2rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: gray;
    text-align: center;
    font-family: "IBM Plex Sans";
}
#courseMangeNameDiv{
    margin-left: 1rem;
}
#accessOptionView{
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    align-items: center;
}
#accessOptionText{
    margin-left: 0.6rem;
    font-size: 0.8rem;
    font-family: "IBM Plex Sans";
}
#courseCreatedDateText{
    font-size: 0.8rem;
    color: gray;
    font-family: "IBM Plex Sans";
    margin-top: 0.3rem;
}

.custom-toggle .react-toggle-track {
    height: 1.5rem;
    width: 2.8rem;
  }
  
  .custom-toggle .react-toggle-thumb {
    width: 1.4rem;
    height: 1.4rem;
  }
  .custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: blue;
  }

 