#tableCodeStyle{
    background-color: #FFECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    font-family: "IBM Plex Sans";
    font-weight: 600;
}
#tableStatusStyle{
    background-color: #FFECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    border-radius: 1rem; 
    color: #563C36;
    width: 6rem;
    font-family: "IBM Plex Sans";
    font-weight: 600;
}
#tablePercentageStyle{
    color: #0C67EF;
    font-family: "IBM Plex Sans";
    font-weight: 600;
}
#tableActionDiv{
    display: flex;
    flex-direction: row;
    width: 250px;
}
#tableEditBtn{
    color: #AE2A00;
    font-weight: 600;
    font-family: "IBM Plex Sans";
    margin-right: 1rem;
    background-color: #fff;
    border: #fff;
}
#tableRemoveBtn{
    color: #ED122C;
    font-weight: 600;
    font-family: "IBM Plex Sans";
    background-color: #fff;
    border: #fff;
}
#tableDateStyle{
    font-family: "IBM Plex Sans";
    font-weight: 600;
}