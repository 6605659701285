.custom-toggle .react-toggle-track {
  height: 1.5rem;
  width: 2.8rem;
}

.custom-toggle .react-toggle-thumb {
  width: 1.4rem;
  height: 1.4rem;
}

.custom-toggle.react-toggle--checked .react-toggle-track {
  background-color: blue;
}

#mainContainer {
  background-color: #f3f4f6;
  padding-bottom: 3rem;
}

#courseDetHeaderRow {
  padding: 1rem;
  border-bottom: 1px solid #8a8888;
}

#courseDetHeaderText {
  font-family: "IBM Plex Sans";
  font-size: 1.45rem;
  font-weight: 600;
}

#courseDetHeaderBtnDiv {
  display: flex;
  justify-content: flex-end;
  /* align-items: center; */
}

#courseBottomRow {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

#classLinkForm {
  box-shadow: none;
  font-family: "IBM Plex Sans";
}

#classLinkLabel {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 1rem;
}

#borderLine {
  background-color: #8a8888;
  height: 1.3px;
}

#addSlotDiv {
  background-color: #bcd3e5;
  border-radius: 0.8rem;
  padding: 0.2rem;
  margin-right: 1rem;
  padding-bottom: 4.23rem;
  padding-top: 0.6rem;
}

#addSlotText {
  font-family: "IBM Plex Sans";
  font-weight: bold;
  margin-left: 1rem;
  margin-top: 0.8rem;
  margin-bottom: 2rem;
}

#showCourseBatchRow {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 0.7rem;
  cursor: pointer;
}

#showCourseBatchRow:hover::-webkit-scrollbar {
  display: block;
  transition: display 500ms;
  height: 10px;
}

#showCourseBatchRow::-webkit-scrollbar {
  display: none;
  transition: 1s all linear;
}

#showCourseBatchRow:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 1rem;
}

#showCourseBatchRow:-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#batchLabel {
  font-family: "IBM Plex Sans";
  font-weight: bold;
  margin-bottom: 0.75rem;
  margin-top: 1rem;
}

#ratingContainer {
  border-radius: 5rem;
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

#ratingRow {
  background-color: "white";
  height: 65vh;
  overflow: scroll;
  border-radius: 10px;
}

#ratingRow:hover::-webkit-scrollbar {
  display: block;
  height: 10px;
  width: 10px;
  transition: 1s all linear;
}

#ratingRow::-webkit-scrollbar {
  display: none;
  transition: 1s all linear;
}

#ratingRow:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 1rem;
}

#ratingRow:-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
#courseNameText {
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "IBM Plex Sans";
}

#courseDescriptionText {
  font-family: "IBM Plex Sans";
  font-size: 0.95rem;
  font-weight: 500;
  color: gray;
  text-align: justify;
}

#courseImgCol {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
}

#courseImg {
  border-radius: 1rem;
  width: 90%;
  height: 12rem;
}