body {
  background-color: #fafafb;
}
#dash1 {
  margin-top: 2rem;
}
#dash2 {
  margin-top: 1rem;
}

#dash3 {
  margin-top: 0rem;
}

@media only screen and (max-width: 600px) {
  #dash3 {
    margin-top: 1rem;
  }
}

#dash4 {
  text-decoration: none;
  color: initial;
}

#dash5 {
  width: 50%;
  border: none;
  font-weight: 300;
  text-align: center;
}
#dash6 {
  font-weight: 800;
  font-size: 1.4rem;
}
#dash7 {
  display: flex;
  justify-content: center;
}

/* 
.form-select {
    background-position: 6rem center; 
} */

#dash8 {
  height: 0.3rem;
  width: 33%;
  background: linear-gradient(90.82deg, #f05904 4.05%, #fc3973 50.7%);
  margin: auto;
}
#dash9 {
  margin-top: 1rem;
  background: #ffffff;
  box-shadow: 6px -1px 26px rgb(0 0 0 / 25%);
  border-radius: 1rem;
  padding: 1rem;
}

#dash10 {
  font-size: 1.3rem;
  font-weight: 500;

  font-family: "IBM Plex sans", sans-serif;
}

#dash11 {
  font-family: "IBM Plex sans", sans-serif;
  font-weight: 400;
}
#dash12 {
  background-color: #fff;
  box-shadow: 9px 12px 37px 0px rgba(0, 0, 0, 0.12);
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 1rem;
}
#dash13 {
  color: #72a323;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash14 {
  color: #e41c70;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash15 {
  color: #4a1cd3;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash16 {
  color: #08bade;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash17 {
  color: #396aff;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash18 {
  color: #1964ce;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash19 {
  color: #e80e0e;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash20 {
  color: #05a21e;
  font-size: 1.8rem;
  margin-top: 0.3rem;
}
#dash21 {
  padding: 1rem;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 9px 12px 37px 0px rgba(0, 0, 0, 0.12);
  /* height: 60vh; */
  position: relative;
}
#dash22 {
  color: #596a8c;
  font-size: 16px;
  font-weight: 600;
}
#dash23 {
  padding: 1rem 0rem 1rem 1.5rem;
  margin-top: 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 9px 12px 37px 0px rgba(0, 0, 0, 0.12);
  height: 16rem;
}
.dash24 {
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 1.2rem;
  font-weight: 400;
  color: rebeccapurple;
}
