body {
  background-color: #fafafb;
}

#prv1 {
  font-size: 1.3rem;
  font-weight: bold;
  color: #124;
  font-family: "IBM Plex Sans", sans-serif;
}

#prv2 {
  display: flex;
  justify-content: flex-end;
}
#prv3{
    background-color: #fff;
    box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30);
    border-radius: 10px;
    font-family: "IBM Plex Sans", sans-serif;
    padding: 1rem;
    color: #124;
    margin-bottom: 1rem;
}
#prv4{
    background-color: #D1E0EB;
    /* box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.30); */
    border-radius: 10px;
    font-family: "IBM Plex Sans", sans-serif;
    padding: 1rem;
    color: #124;
    margin-bottom: 1rem;
}

#prv5{
    font-family: "IBM Plex Sans", sans-serif;
    padding: 8px 16px;
    background-color: #fff;
    color: #F23935;
    border-radius: .4rem;
    border: 1px solid #F23935;
    box-shadow: none;
    margin-right: 10px;
}

#prv6{
    background-color: #124;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: .4rem;
    padding: 8px 16px;
    margin-right: 10px;
}

#prv9 {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}