#courseadmin1{
    padding: 1rem;
    margin-top: 0.4rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    margin-bottom: 1rem;
    font-family: IBM Plex Sans;
}
#courseadmin2{
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 0.3rem;
}
#courseadmin3{
    font-size: 1.1rem;
    font-weight: 600;
}
#courseadmin4{
    font-size: 1rem;
    font-weight: 500;
    color: rgb(135, 135, 135);
    margin-top: 0.1rem;
}
#courseadmin5{
    margin-top: 0.3rem;
}
#courseadmin6{
    display: flex;
    justify-content: flex-end;
}
#courseadmin7{
    font-size: 1.1rem;
    font-weight: 500;
    font-family: IBM Plex Sans;
}
#courseadmin8{
    padding: 1rem;
    /* margin-top: 0.4rem; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#courseadmin9{
    margin-top: 2.5rem;
}
#courseadmin10{
    width: 100%;
    background-image: linear-gradient(to top,#381FD1,#396AFF);
    height:2.2rem;
}
#courseadmin11{
    font-size: 1.1rem;
    margin-top: 0.5rem;
}
#courseadmin12{
    margin-top: 1rem;
}
#courseadmin13{
    margin-top: 1.1rem;
    margin-left: 0.3rem;
    padding: 1rem;
    /* margin-top: 0.4rem; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
    height:11.3rem;
}
#courseadmin14{
    margin-top: 1rem;
    padding: 1rem;
    /* margin-top: 0.4rem; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#courseadmin15{
    font-size: 1.1rem;
    font-weight: 500;
    margin-top: 1rem;
}
#courseadmin16{
    padding: 1rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#courseadmin17{
    margin-top: 0.5rem;
}
#courseadmin18{
    color:#FF0000;
    font-size: 1.1rem;
    font-weight: 500;
}
#courseadmin19{
    text-align: center;
    background-color: #EBF2FA;
    color: #1C56C9;
    font-weight: 500;
    margin-top: 0.1rem;
}
#courseadmin20{
    margin-top: 0.3rem;
    color:#112244;
    font-size: 12px;
    font-weight: 400;
}
#courseadmin21{
    font-size: 24px;
    font-weight: 600;
    color:#112244;
}
#courseadmin22{
    margin-left: 0.3rem;
    color:#CCCCCC;
}
#courseadmin23{
    margin-top: 0.6rem;
}
#courseadmin24{
    width:100%;
    background-color: #112244;
    color:white;
}
#courseadmin25{
    margin-top: 1rem;
}
#courseadmin26{
    margin-top: 1.5rem;
}
#courseadmin27{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 600;
}