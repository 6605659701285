.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1814f3 !important;
  font-family: "IBM Plex Sans";
  font-size: 13px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

#sectionNameLabel {
  font-family: "IBM Plex Sans";
  font-weight: 600;
  font-size: 1rem;
}

#sectionNameInput {
  box-shadow: none;
  font-family: "IBM Plex Sans";
}

#courseContentContainer {
  height: 380px;
  overflow-y: scroll;
  border-radius: 10px;
  background-color: #bcd3e5;
  padding-top: 1rem;
  padding-bottom: 3rem;
  border-radius: 0.7rem;
  margin-bottom: 2rem;
}

#courseContentContainer:hover::-webkit-scrollbar {
  display: block;
  height: 10px;
  width: 10px;
  transition: 1s all linear;
  background-color: "transparent";
}

#courseContentContainer::-webkit-scrollbar {
  display: none;
  transition: 1s all linear;
}

#courseContentContainer:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 1rem;
}

#courseContentContainer:-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#courseContentLabel {
  font-weight: 600;
  font-size: 1.4rem;
  font-family: "IBM Plex Sans";
  margin-bottom: 1rem;
  margin-left: 1rem;
}

#courseSectionsContainer {
  background-color: #112244;
  color: white;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.2rem;
  font-family: "IBM Plex Sans";
  cursor: pointer;
  transition: 1s all linear;
}

#sectionIconsCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#sectionIcons {
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#courseChapterContainer {
  padding-left: 3rem;
  padding-right: 3rem;
  margin-bottom: 0.15rem;
  margin-top: 0.15;
  font-family: "IBM Plex Sans";
}

#noSectionAddedText {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  text-align: center;
}

#chapterText {
  cursor: pointer;
}

#thumbnail {
  border-radius: 1rem;
  object-fit: cover;
  height: 220px;
  width: 390px;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}