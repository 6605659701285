#courseadmincards1{
    padding: 1rem;
    /* margin-top: 0.4rem; */
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(207, 216, 228, 0.4);
    border: 1px solid rgb(233, 232, 232);
}
#courseadmincards2{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.8rem;
}
#courseadmincards3{
    color: #718EBF;
    font-size: 16px;
    font-weight: 400;
}
