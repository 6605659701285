#dashboardcard1 {
  border-radius: 0.5rem;
  padding: 1rem 0rem 1rem 1.5rem;
  box-shadow: 9px 12px 37px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
#dashboardcard2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #112244;
  opacity: 80%;
}
#dashboardcard3 {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  color: #112244;
}

#dashboardcard5 {
  width: 100%;
}
