body {
  background-color: #f6f9ff;
}

#editslot1 {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.3);
  margin-top: 2rem;
  margin-bottom: 1rem;
}
#editslot2 {
  font-family: "IBM Plex sans", sans-serif;
  font-weight: 600;
  font-size: 1.4rem;
  color: #124;
}
#editslot3 {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: #124;
}

#editslot4 {
  width: 100%;
  font-weight: 500;
  background-color: #124;
  box-shadow: none;
  border: none;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  padding: 0.7rem;
}

#editslot5 {
  cursor: pointer;
  font-size: 1.4rem;
  position: absolute;
  right: -20px;
  bottom: -10px;
}
#editslot6 {
  color: red;
  padding: 0.5rem;
  margin-right: 10px;
  position: relative;
}
#editslot7 {
  color: #124;
  background-color: #fff;
}
#editslot8 {
  width: 100%;
  background-color: slategray;
  color: #fff;
  border: none;
  box-shadow: none;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

#editslot9 {
  background-color: #124;
  color: #fff;
  font-weight: 500;
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}
#editslot10 {
  background-color: #124;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 600px) {
  #editslot10 {
    background-color: #124;
    color: #fff;
    padding: 0.5rem;
    font-size: 0.8rem;
    text-align: center;
  }

  #editslot6 {
    background-color: #f23935;
    color: #fff;
    padding: 0.5rem;
    margin-right: 10px;
    font-size: 0.8rem;
  }
}
