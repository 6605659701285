#tcc1 {
  background: #ffffff;
  box-shadow: 1px 5px 5.8px 0px rgba(176, 184, 202, 0.3);
  border-radius: 10px;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
#tcc2 {
  font-family: Jost;
  font-weight: 400;
  text-align: justify;
  margin: 0.5rem 0 0.5rem 0;
}
#tcc3 {
  display: flex;
  justify-content: center;
  position: relative;
}
#tcc4 {
  font-size: 0.6rem;
  font-weight: 500;
  margin: 0 0 0.5rem 0;
}
#tcc6 {
  text-decoration: none;
  color: initial;
}
#trcc7 {
  width: 2rem;
  height: 2rem;
  color: #124;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1rem;
  top: 0rem;
  padding: 6px;
}
#trcc9 {
  position: absolute;
  left: 0.2rem;
  top: 0.1rem;
}
#trcc8 {
  width: 100%;
  height: 16rem;
  border-radius: 0.5rem;
  cursor: pointer;
  object-fit: fill;
  border-radius: 5px;
}
#trcc10 {
  font-weight: 700;
  font-size: 20px;
  color: #112244;
}
#tcc11 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#trcc12 {
  color: #0b3ae1;
  width: 100%;
}
#trcc13 {
  color: #17a88f;
  width: 100%;
  font-family: "IBM Plex sans", sans-serif;
  font-weight: 600;
}
#trcc14 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "IBM Plex sans", sans-serif;
}
#tcc15 {
  color: #1C56C9;
  font-weight: 500;
  font-size: 14px;
  background: #EBF2FA;
  padding: 0 5px;
}
#tcc16 {
  color: gray;
  font-weight: 500;
  font-size: 14px;
  background: #EBF2FA;
  padding: 0 5px;
}
#tcc17 {
  font-weight: 500;
  font-family: "IBM Plex sans", sans-serif;
  text-align: justify;
  margin: 0.5rem 0 0.5rem 0;
  display: flex;
  justify-content: space-between;
  padding-right: 1rem;
  color: #FF0000;
}

#trcc18{
    display: flex;
    justify-content: flex-end;
}
#trcc19{
    margin-top: .2rem;
    margin-bottom: .8rem;
}