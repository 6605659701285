
#emailFieldVerify {
    position: absolute;
    color: rgb(9, 70, 192);
    font-weight: bold;
    font-family: "IBM Plex Sans", sans-serif;
    cursor: pointer;
    text-decoration: underline;
    top: 2.5rem;
    right: 2rem;
}

.emailField {
    font-family: "IBM Plex Sans", sans-serif;
    color: white;
    font-weight: 500;
    font-size: 0.9rem;
}
.reset1{
    background-size: cover;
    background-image: url("../../assets/login/backgroundImage.png");
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetBack{
    position: absolute;
    top: 30px;
    left: 30px;
}